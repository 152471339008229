import { render, staticRenderFns } from "./Logos1.vue?vue&type=template&id=bed51aee&scoped=true&"
import script from "./Logos1.vue?vue&type=script&lang=js&"
export * from "./Logos1.vue?vue&type=script&lang=js&"
import style0 from "./Logos1.vue?vue&type=style&index=0&id=bed51aee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed51aee",
  null
  
)

export default component.exports