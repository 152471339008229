import { render, staticRenderFns } from "./index.vue?vue&type=template&id=54ceb71b&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero10: require('/usr/app/components/Hero10.vue').default,Hero5r: require('/usr/app/components/Hero5r.vue').default,Logos1: require('/usr/app/components/Logos1.vue').default,Testimonial4: require('/usr/app/components/Testimonial4.vue').default})
