
export default {
  props: {
    isdark: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Object,
      default: () => ({
        start: undefined,
        end: undefined,
        concat: true,
      }),
    },
    textBlock: {
      type: String,
      default: '',
    },
    btn1: {
      type: Object,
      default: () => ({
        label: null,
      }),
    },
    btn2: {
      type: Object,
      default: () => null,
    },
    ximage: {
      type: String,
      default: '',
    },
    xjustify: {
      type: String,
      default: 'left',
    },
  },

  computed: {
    centerClass() {
      if (this.xjustify === 'left') {
        return (
          (this.isdark ? 'dark ' : '') + 'w-full w-md-half d-flex align-center'
        )
      } else {
        return (
          (this.isdark ? 'dark ' : '') + 'w-full w-md-full d-flex align-center'
        )
      }
    },
  },

  methods: {
    registerPage() {
      this.$router.push('/services')
    },
  },
}
