
export default {
  props: {
    title: {
      type: Object,
      default: () => ({
        start: undefined,
        end: undefined,
        concat: true,
      }),
    },
    textBlock: {
      type: String,
      default: '',
    },
    textBlockList: {
      type: Array,
      default: () => {
        return []
      },
    },
    btn1: {
      type: Object,
      default: () => null,
    },
    btn2: {
      type: Object,
      default: () => null,
    },
    ximage: {
      type: String,
      default: '',
    },
  },
  computed: {
    contatChar() {
      return this.title.contat ? '&nbsp;' : '<br />'
    },
    cImage() {
      return require('~/assets/images/' + this.xImage)
    },
  },
}
