
export default {
  props: {
    logoList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
